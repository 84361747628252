import { useCallback, useState } from 'react'
import { Room } from '../../../domain/models/Room'
import useSubscription from './useSubscription'

const useRoom = (roomId: string) => {
  const [room, setRoom] = useState<Room | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | null>(null)
  const updateRoom = useCallback(
    (message) => {
      try {
        if (!message) throw new Error()
        const updatedRoom: Room = message
        setRoom(updatedRoom)
        setLoading(false)
      } catch (e) {
        setError('Error updating room')
        setLoading(false)
      }
    },
    [setRoom, setLoading, setError]
  )

  useSubscription(`/room/${roomId}`, updateRoom)

  return { room, loading, error }
}

export default useRoom
