import { useEffect, useState } from 'react'
import { Camera, UseCameras } from './useCameras'

const useCameras: UseCameras = () => {
  const [cameras, setCameras] = useState<Camera[]>([])
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    async function fetchCameras() {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices()
        const videoDevices = devices.filter(
          (device) => device.kind === 'videoinput'
        )
        const cameras = videoDevices.map((device) => ({
          name: device.label || 'Unnamed Camera',
          object: device,
        }))
        setCameras(cameras)
      } catch (err) {
        setError(`Error fetching cameras: ${err.message}`)
      }
    }

    // Request video permission and then fetch cameras
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then(() => fetchCameras())
      .catch((err) => {
        setError(`Permission error: ${err.message}`)
      })
  }, [])

  return { cameras, error }
}

export default useCameras
