import { useNavigation, useRoute } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import {
  Box,
  FlatList,
  HStack,
  Image,
  ScrollView,
  Text,
  VStack,
} from 'native-base'
import { useCallback, useEffect, useState } from 'react'
import Markdown from 'react-native-marked'
import {
  Activity,
  ActivityRegistrationState,
  ActivityType,
} from '../../../../../domain/models/interfaces/activityInterfaces'
import { openUrl } from '../../../../../domain/services/LinkService'
import mdStylesDesktop from '../../../constants/mdStylesDesktop'
import useGetIsImage from '../../../hooks/useGetIsImage'
import useGetMeta from '../../../hooks/useGetMeta'
import { useIframeSource } from '../../../hooks/useIframeSource'
import DefaultLayout from '../../../layouts/DefaultLayout'
import { useUserProfileContext } from '../../../providers/UserProfileProvider'
import { NavigationParams } from '../../../screens/NavigationScreen'
import BackButton from '../../navigation/BackButton'
import ActivityCard from '../../_shared/card/ActivityCard'
import FixedContainer from '../../_shared/layout/FixedContainer'
import AudioDesktopHeader from '../audio/AudioDesktopHeader'
import VideoPlayer from '../video/VideoPlayer'
import ActivityActionButtonAndModal from './ActivityActionButtonAndModal'
import AddToCalendarButtonAndModal from './AddToCalendarButtonAndModal'
import ContentHeader from './ContentHeader'
import ActivityDownloadableContentButtons from './downloadableContent/ActivityDownloadableContentButtons'
import MessageAllButtonAndModal from './MessageAllButtonAndModal'
import MomentFrame from './moment/MomentFrame'
import StepCompletionForm from './StepCompletionForm'

const buttonStyle = {
  marginRight: '32px',
  marginLeft: '32px',
  minWidth: '240px',
  height: '48px',
}

const ActivityHasHeader = (activityType) => {
  switch (activityType) {
    case ActivityType.AUDIO:
    case ActivityType.VIDEO:
    case ActivityType.MOMENT:
    case ActivityType.INTERACTIVE:
    case ActivityType.QUIZ:
      return true
    default:
      return false
  }
}

const ActivityHeader = (activity: Activity, videoAspectRatioCallback) => {
  const { userProfile } = useUserProfileContext()
  const videoUrl = useIframeSource(
    (activity.type === 'Interactive' && activity.videoUrl) || '',
    false
  )
  const quizUrl = useIframeSource(
    (activity.quizUrl !== '' && activity.quizUrl) || '',
    false
  )
  switch (activity.type) {
    case ActivityType.AUDIO:
      return <AudioDesktopHeader activity={activity} />
    case ActivityType.VIDEO:
      return (
        <VideoPlayer
          activity={activity}
          autoPlay={true}
          videoAspectRatioCallback={videoAspectRatioCallback}
        />
      )
    case ActivityType.MOMENT:
      return <MomentFrame momentUrl={activity.sevenTapsUrl} />

    case ActivityType.INTERACTIVE:
      return (
        <MomentFrame
          momentUrl={`${videoUrl}#member_id=${userProfile.id}&activity_id=${activity.id}`}
        />
      )

    case ActivityType.QUIZ:
      return (
        <MomentFrame
          momentUrl={`${quizUrl}#member_id=${userProfile.id}&activity_id=${activity.id}`}
        />
      )

    default:
      return null
  }
}

const ActivityDescription = (activity, isImage, aspectRatio) => {
  const isModerator =
    activity.registration === ActivityRegistrationState.MODERATING
  switch (activity.type) {
    case ActivityType.ARTICLE:
    case ActivityType.ACTIVITY:
      if (activity.downloadUrl) {
        return (
          <HStack mt="40px" mr="2%" justifyContent="space-between">
            <Box flex="2" mr="2%">
              <Text>{activity.description}</Text>
            </Box>
            <VStack flex="1" ml="72px">
              <Box
                overflow="hidden"
                w="240px"
                style={{ aspectRatio: aspectRatio }}
                shadow={5}>
                {!isImage ? (
                  <Box
                    justifyContent="center"
                    alignItems="center"
                    position="absolute"
                    top="-5px"
                    left="-10px">
                    <iframe
                      seamless={true}
                      style={{
                        width: '278px',
                        height: '250px',
                        pointerEvents: 'none',
                        borderWidth: 0,
                        resize: '',
                      }}
                      src={activity.downloadUrl}
                    />
                  </Box>
                ) : (
                  <Image
                    source={{
                      uri: activity.downloadUrl,
                    }}
                    alt="Full image"
                    style={{ flex: 1 }}
                    resizeMode="contain"
                  />
                )}
              </Box>
              <Box w="240">
                <ActivityDownloadableContentButtons
                  activity={activity}
                  downloadLocation={activity.downloadUrl}
                />
              </Box>
            </VStack>
          </HStack>
        )
      } else {
        return (
          <Box mt="20px">
            <Markdown
              value={activity.article}
              flatListProps={{
                initialNumToRender: 8,
                contentContainerStyle: { backgroundColor: 'white' },
              }}
              styles={mdStylesDesktop}
            />
          </Box>
        )
      }
    default:
      let startDate = new Date(activity.startDate)
      startDate.setMinutes(startDate.getMinutes() - 15)
      const isJoinable = startDate < new Date() && activity.endDate > new Date()
      let newStartDate = new Date()
      newStartDate.setMinutes(newStartDate.getMinutes() + 16)
      return (
        <HStack mt="20px" justifyContent="space-between">
          <Markdown
            value={activity.description}
            flatListProps={{
              initialNumToRender: 8,
              contentContainerStyle: { backgroundColor: 'white' },
            }}
            styles={mdStylesDesktop}
          />
          {isGroup(activity.type) && (
            <VStack>
              <Box mb="16px">
                <ActivityActionButtonAndModal
                  handleClose={() => {}}
                  activity={activity}
                  buttonStyle={buttonStyle}
                />
              </Box>
              <AddToCalendarButtonAndModal
                handleClose={() => {}}
                activity={activity}
                buttonHidden={
                  !(
                    activity.registration ===
                      ActivityRegistrationState.ATTENDING &&
                    !isJoinable &&
                    !activity.ended
                  )
                }
                buttonStyle={buttonStyle}
              />
              {isModerator && (
                <MessageAllButtonAndModal
                  activity={activity}
                  buttonProps={{
                    marginRight: '32px',
                    marginLeft: '32px',
                    minW: '240px',
                    height: '48px',
                  }}
                />
              )}
            </VStack>
          )}
        </HStack>
      )
  }
}
const isGroup = (activityType) => {
  return (
    activityType === ActivityType.GROUP_LISTEN ||
    activityType === ActivityType.GROUP_OTHER ||
    activityType === ActivityType.GROUP_SERIES ||
    activityType === ActivityType.GROUP_SKILL ||
    activityType === ActivityType.GROUP_SUPPORT
  )
}

const ActivityContentDesktop = ({ activity, isLoading }) => {
  const navigation =
    useNavigation<NativeStackNavigationProp<NavigationParams>>()
  const route = useRoute()
  const stepId = (route.params as { stepId?: string })?.stepId
  const hasHeader = ActivityHasHeader(activity?.type)
  const isImage = useGetIsImage(activity?.downloadUrl)
  const { userProfile } = useUserProfileContext()
  const [aspectRatio, setAspectRatio] = useState(240 / 230)
  const [headerAspectRatio, setHeaderAspectRatio] = useState(7 / 4)

  useEffect(() => {
    setHeaderAspectRatio(
      activity?.type === ActivityType.VIDEO ? 1 / 1.7 : 7 / 4
    )
  }, [activity])

  const videoAspectRatioCallback = useCallback(
    (data) => {
      setHeaderAspectRatio(
        data.player.player.container.firstChild.width /
          data.player.player.container.firstChild.height
      )
    },
    [setHeaderAspectRatio]
  )

  const isVerticalVideo =
    activity?.type === ActivityType.VIDEO && headerAspectRatio < 1

  useEffect(() => {
    if (isImage)
      useGetMeta(activity?.downloadUrl, (err, img) => {
        setAspectRatio(img.naturalWidth / img.naturalHeight)
      })
    else {
      setAspectRatio(240 / 230)
    }
  }, [activity?.downloadUrl])

  const openQuiz = () => {
    openUrl(
      `${activity.quizUrl}?member_id=${userProfile.id}&activity_id=${activity.id}`,
      true
    )
  }

  return (
    !isLoading && (
      <DefaultLayout bg="muted.50" activeLabel="Explore">
        <FixedContainer width="1024px">
          <BackButton color="muted.400" isDisabled />
          <ScrollView
            marginBottom="16px"
            borderWidth={1}
            borderColor="grey.200"
            backgroundColor="white"
            borderRadius="20px"
            p="40px"
            horizontal={isVerticalVideo}>
            {hasHeader && (
              <Box
                width={!isVerticalVideo ? '944px' : '376px'}
                height={
                  !isVerticalVideo
                    ? 944 / headerAspectRatio + 'px'
                    : 376 / headerAspectRatio + 'px'
                }>
                {ActivityHeader(activity, videoAspectRatioCallback)}
              </Box>
            )}
            <Box
              pb={isVerticalVideo ? 0 : 8}
              backgroundColor="white"
              maxW={isVerticalVideo ? '528px' : undefined}
              ml={!isVerticalVideo ? undefined : '40px'}>
              <Box mt="20px">
                <ContentHeader activityHeader={activity} />
                {ActivityDescription(activity, isImage, aspectRatio)}
                {stepId && <StepCompletionForm stepId={stepId} />}
                {!stepId && activity?.recommendations.length > 0 && (
                  <VStack mb={16} width="101%">
                    <Text
                      fontSize="16px"
                      color="primary.900"
                      fontWeight={700}
                      mt={4}>
                      Related
                    </Text>
                    <FlatList
                      numColumns={1}
                      horizontal
                      showsHorizontalScrollIndicator={false}
                      keyExtractor={(item) => item.id}
                      data={activity?.recommendations}
                      renderItem={({ item, index }) => {
                        return (
                          <Box
                            ml={index === 0 ? 0.5 : 0}
                            my={4}
                            width="304px"
                            mr={4}>
                            <ActivityCard
                              activity={item}
                              onPress={async () =>
                                navigation.navigate('Activity', {
                                  activityId: item.id,
                                })
                              }
                            />
                          </Box>
                        )
                      }}
                    />
                  </VStack>
                )}
              </Box>
            </Box>
          </ScrollView>
        </FixedContainer>
      </DefaultLayout>
    )
  )
}

export default ActivityContentDesktop
