import { Box, HStack, VStack } from 'native-base'
import { useEffect } from 'react'
import { ImageBackground, StyleSheet } from 'react-native'
import useIntercom from '../../../hooks/useIntercom.web'

const BackgroundImage = require('../../../assets/images/room/background-beach.webp')

const BrowserRoom = () => {
  const { update } = useIntercom()

  useEffect(() => {
    update({ hideDefaultLauncher: true })
  }, [])

  const footerHeight = 64
  const marginBottom = `${footerHeight + 4}px`

  return (
    <ImageBackground source={BackgroundImage} style={styles.backgroundImage}>
      <VStack flex={1} p={10}>
        <Box
          opacity={0.9}
          borderRadius={4}
          height="40px"
          width="100%"
          mb={2}
          background="black">
          {/* Alert screenshare component */}
        </Box>
        <HStack
          marginBottom={marginBottom}
          justifyContent="space-between"
          alignItems="center"
          flex={1}>
          <Box
            padding={3}
            borderRadius={8}
            h="100%"
            width="320px"
            background="white">
            {/* Participants component */}
          </Box>
          <Box
            alignSelf="flex-start"
            height="340px"
            maxWidth="640px"
            flex={1}
            mx={3}
            borderRadius={6}
            background="black">
            {/* Video component */}
            <Box
              borderRadius={6}
              position="absolute"
              right={0}
              m={2}
              width="167px"
              height="109px"
              backgroundColor="white">
              {/* Video component when screen share is enabled */}
            </Box>
          </Box>
          <Box
            padding={3}
            borderRadius={8}
            h="100%"
            width="320px"
            background="white">
            {/* Chat component */}
          </Box>
        </HStack>
      </VStack>
      <Box
        position="absolute"
        bottom="0"
        h={`${footerHeight}px`}
        w="100%"
        bg="black"
        safeAreaBottom>
        {/* Footer component */}
      </Box>
    </ImageBackground>
  )
}

const styles = StyleSheet.create({
  backgroundImage: {
    width: '100%',
    height: '100%',
  },
})

export default BrowserRoom
