import { useRoute } from '@react-navigation/native'
import { useBreakpointValue } from 'native-base'

import BrowserRoom from '../../components/liveGroup/room30/BrowserRoom'
import MobileRoom from '../../components/liveGroup/room30/MobileRoom'
import secureScreen from '../../hooks/authentication/secureScreen'

const RoomScreen30 = () => {
  const breakpoint = useBreakpointValue({
    base: 'mobile',
    lg: 'browser',
  })

  const route = useRoute()
  const roomId = (route.params as { roomId: string }).roomId
  // const { room } = useRoom(roomId)

  return breakpoint === 'mobile' ? <MobileRoom /> : <BrowserRoom />
}

export default secureScreen(RoomScreen30)
