import { Avatar, Button, Flex, Text } from 'native-base'
import { useEffect } from 'react'
import Card from '../../_shared/card/Card'
import FaIcon from '../../_shared/FaIcon'
import Overlay from '../../_shared/Overlay'

const OrientationStep = ({
  index,
  condition,
  stepperIndex,
  setStepperIndex,
  title,
  body,
  isLinear = false,
  isDisabled = false,
  isLast,
  onPressNext = () => {},
  onPressBack = () => {},
}) => {
  useEffect(() => {
    if (!isLinear && condition && !isLast && index >= stepperIndex) {
      setStepperIndex(index + 1)
    }
  }, [condition])

  const allowNext = !isDisabled && condition

  return (
    <Flex width="340">
      {isDisabled && <Overlay borderRadius="md" />}
      <Card
        boxProps={{
          h: '490',
          w: '340',
        }}
        titleRightIcon={
          <Avatar size="xs" bg={allowNext ? 'success.700' : 'muted.500'}>
            {allowNext ? (
              <FaIcon name="check" size={16} color="white" />
            ) : (
              <Text fontSize="md" color="white">
                {index + 1}
              </Text>
            )}
          </Avatar>
        }
        title={title}
        body={body}
        actions={
          isLinear && (
            <Button.Group justifyContent="space-between" width="100%">
              <Button
                variant="ghost"
                borderColor="muted.300"
                borderWidth={1}
                onPress={onPressBack}>
                <Text fontWeight={500}>Back</Text>
              </Button>
              <Button
                onPress={onPressNext}
                colorScheme="primary"
                isDisabled={isLast || !condition}>
                <Text color="white" fontWeight={500}>
                  Next
                </Text>
              </Button>
            </Button.Group>
          )
        }
      />
    </Flex>
  )
}

export default OrientationStep
