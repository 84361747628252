import { Box, Flex, Select, Text } from 'native-base'
import { useEffect, useState } from 'react'
import useMicrophones from '../../../hooks/useMicrophones'
import FormLabel from '../../_shared/form/FormLabel'
import PermissionCheck from './PermissionCheck'

const DeviceSettings = ({ setStepCondition, stepIndex }) => {
  const { microphones, error } = useMicrophones()
  const [selectedMic, setSelectedMic] = useState('')
  const [hasPermission, setHasPermission] = useState(false)

  useEffect(() => {
    if (microphones.length > 0) {
      setSelectedMic(microphones[0].object?.deviceId || 'no-mic')
      setStepCondition(stepIndex, true)
    }
  }, [microphones])

  const handleMicChange = (value) => {
    setSelectedMic(value)
    setStepCondition(stepIndex, value !== '')
  }

  useEffect(() => {
    if (error) {
      setHasPermission(false)
    } else {
      setHasPermission(true)
    }
  }, [error])

  const requestPermission = () => {
    // Implement the logic to request permission here
  }

  return (
    <Flex mb="5">
      <PermissionCheck
        hasPermission={hasPermission}
        requestPermission={requestPermission}
      />
      <FormLabel color="muted.500">Microphone</FormLabel>
      <Select
        selectedValue={selectedMic}
        placeholder="Select Microphone"
        size="lg"
        onValueChange={handleMicChange}>
        {microphones.map((mic, index) => (
          <Select.Item
            key={mic.object?.deviceId ?? 'no-mic'}
            value={mic.object?.deviceId || 'no-mic'}
            label={mic.name}
          />
        ))}
      </Select>
      {selectedMic === 'no-mic' && hasPermission && (
        <Box mt={4}>
          <Text fontWeight="bold" color="primary.900">
            No microphone?
          </Text>
          <Text color="primary.900">
            • You’ll still be able to join and participate!
          </Text>
          <Text color="primary.900">
            • Remember you’re able to use the hand-raise and chat functions
          </Text>
        </Box>
      )}
    </Flex>
  )
}

export default DeviceSettings
