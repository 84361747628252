import { useEffect, useState } from 'react'
import { Microphone, UseMicrophones } from './useMicrophones'

const useMicrophones: UseMicrophones = () => {
  const [microphones, setMicrophones] = useState<Microphone[]>([])
  const [error, setError] = useState<string | null>(null)
  const NO_MICROPHONE = { name: 'No microphone', object: null }

  useEffect(() => {
    async function fetchMicrophones() {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices()
        const audioDevices = devices.filter(
          (device) => device.kind === 'audioinput'
        )
        const microphones = audioDevices.map((device) => ({
          name: device.label || 'Unnamed Microphone',
          object: device,
        }))
        microphones.push(NO_MICROPHONE)

        setMicrophones(microphones)
      } catch (err) {
        setMicrophones([NO_MICROPHONE])
        setError(`Error fetching microphones: ${err.message}`)
      }
    }

    // Request audio permission and then fetch microphones
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(() => fetchMicrophones())
      .catch((err) => {
        setMicrophones([NO_MICROPHONE])
        setError(`Permission error: ${err.message}`)
      })
  }, [])

  return { microphones, error }
}

export default useMicrophones
