import axios from 'axios'
import * as Application from 'expo-application'
import { Box, Center, Heading, Text, useToast } from 'native-base'
import { createContext, useContext, useRef, useState } from 'react'
import { Alert, Linking, Platform, Pressable } from 'react-native'
import Modal from 'react-native-modal'
import { Activity } from '../../../domain/models/interfaces/activityInterfaces'
import { MemberProfile } from '../../../domain/models/interfaces/models'
import analytics from '../../../domain/services/analytics/AnalyticsService'
import clevertap from '../../../domain/services/clevertap/ClevertapAdapter'
import Configuration from '../../../domain/services/ConfigurationService'
import MixpanelAdapter from '../../../domain/services/mixpanel/MixpanelAdapter'
import * as RootNavigation from '../../../domain/services/RootNavigation'
import useAuthentication from '../hooks/authentication/useAuthentication'
import useGroupEventProperties from '../hooks/useGroupEventProperties'
import useInvalidateQueries from '../hooks/useInvalidateQueries'
import useParseActivity from '../hooks/useParseActivity'

const CabanaApiContext = createContext({
  AuthPasswordlessApi: undefined,
  ActivityApi: undefined,
  ActivityCardApi: undefined,
  ActivityDetailsApi: {
    details: (activityId: string): Promise<Activity> => null,
  },
  ChatApi: undefined,
  EapApi: undefined,
  MemberSignupApi: undefined,
  MemberProfileApi: undefined,
  OrganizationApi: undefined,
  PersonalizationApi: undefined,
  ProfessionalRoleApi: undefined,
  RoomApi: undefined,
  RoomApiV2: undefined,
  SearchApi: undefined,
  TestApi: undefined,
  VibeCheckApi: undefined,
  ModeratorApi: undefined,
  ReportApi: undefined,
  ReferralApi: undefined,
  VoyageApi: undefined,
  FeatureApi: undefined,
  IngestApi: undefined,
  AdminApi: undefined,
  CollectionApi: undefined,
  LeaderboardApi: undefined,
})

export const useCabanaApi = () => useContext(CabanaApiContext)

export const CabanaApiProvider = ({ children }) => {
  const { getAccessToken } = useAuthentication()
  const { invalidateQueries } = useInvalidateQueries()
  const [webModalShown, setWebModalShown] = useState(false)
  const mobileModalShownRef = useRef(false)
  const qs = require('qs')
  const errorToast = useToast()
  const CabanaApi = axios.create({
    baseURL: Configuration.CABANA_SERVICE_REST_URL,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: 'repeat' }),
  })
  CabanaApi.interceptors.request.use(async (config) => {
    if (!config.url.startsWith('/public/')) {
      config.headers.Authorization = await getAccessToken()
    }
    config.headers['X-App-Version'] =
      Platform.OS === 'web'
        ? Configuration.WEB_VERSION
        : Application.nativeApplicationVersion
    config.headers['X-Platform'] = Platform.OS
    return config
  })
  CabanaApi.interceptors.response.use(
    (response) => response,
    (error) => {
      console.error('REST error', JSON.stringify(error))
      if (error.response.status == 404) {
        RootNavigation.replace('404')
      } else if (error.response.status === 500) {
        errorToast.show({
          render: () => {
            return (
              <Pressable
                onPress={() =>
                  Linking.openURL('mailto:support@mycabana.health')
                    .then((data) => console.debug(data))
                    .catch((data) => console.debug(data))
                }>
                <Box bg="#646464" px="2" py="1" rounded="sm" mb={5}>
                  <Text color="white">
                    Oops! Something went wrong. Contact support at
                    support@mycabana.health.
                  </Text>
                </Box>
              </Pressable>
            )
          },
          placement: 'top',
        })
      } else if (error.response.status === 412) {
        if (Platform.OS === 'ios' && !mobileModalShownRef.current) {
          mobileModalShownRef.current = true
          Alert.alert(
            'A new version of Cabana is available',
            'Please update the app through the App Store before launching',
            [
              {
                text: 'Update',
                onPress: () => {
                  goToAppStore()
                  mobileModalShownRef.current = false
                },
              },
            ]
          )
        } else if (Platform.OS === 'web') {
          setWebModalShown(true)
        }
      } else {
        return Promise.reject(error.response.data.message)
      }
    }
  )

  const MemberProfileApi = {
    profile: (): Promise<MemberProfile> =>
      CabanaApi.get('/member').then((response) => {
        MixpanelAdapter.identify(response.data.id)
        const profile = {
          goal: response.data.goal,
          memberId: response.data.id,
          oasis: response.data.oasis,
          'Professional-Role': response.data.professionalRoles,
          Organization: response.data.organization,
          'Referral-Code': response.data.referralCode,
          'Referral-Count': response.data.referralCount,
          Email: response.data.email,
        }
        clevertap.setProfile(profile)
        MixpanelAdapter.people.set({ ...profile, $email: response.data.email })
        return response.data
      }),
    chooseGoal: (goal: string, isCustomGoal: boolean) =>
      CabanaApi.put(`/member/goal`, {
        goal: isCustomGoal ? 'BOOST_MOOD' : goal.trim(),
        customGoal: isCustomGoal ? goal.trim() : null,
      }),
    chooseMentalHealthMinutes: (minutes: number) =>
      CabanaApi.put('/member/mentalHealthMinutes', { minutes }),
    chooseProfessionalRoles: (professionalRoles: string[]) =>
      CabanaApi.put('/member/professionalRoles', { professionalRoles }),
    chooseOasis: (oasis: string) => CabanaApi.put('/member/oasis', { oasis }),
    getMinutesHistory: () =>
      CabanaApi.get('/member/minutes-history').then(
        (response) => response.data
      ),
    changePassword: () =>
      CabanaApi.post('/member/change-password').then(
        (response) => response.data
      ),
    accessCode: (accessCode: string) =>
      CabanaApi.put('/member/organization', accessCode),
  }

  const MemberSignupApi = {
    validateEmail: (email: string) =>
      CabanaApi.get('/public/sign-up/validate/email', {
        params: { value: email },
      }),
    validateAccessCode: (accessCode: string) =>
      CabanaApi.get('/public/sign-up/validate/accessCode', {
        params: { value: accessCode },
      }),
    ochsnerAccessCode: (email: string) =>
      CabanaApi.post('/public/access-code', {
        organization: 'Ochsner',
        email: email,
      }),
    requestAccessCode: (email: string) =>
      CabanaApi.post('/public/access-code', { email }),
  }

  const AuthPasswordlessApi = {
    lookupAccount: (email: string) =>
      CabanaApi.get('/public/lookup-account', {
        params: { email },
      }),
    lookupAccessCode: (email: string) =>
      CabanaApi.get('/public/lookup-access-code', {
        params: { email },
      }),
    lookupOrganization: (accessCode: string) =>
      CabanaApi.get('/public/lookup-organization', {
        params: { accessCode: accessCode.trim() },
      }),
    requestAccessCode: (email: string, accountEmail: string) =>
      CabanaApi.get('/public/request-access-code', {
        params: {
          email,
          'account-email': accountEmail,
        },
      }),
    createAccount: (
      email: string,
      accessCode: string,
      referralCode: string
    ) => {
      return CabanaApi.post('/public/create-account', {
        email,
        accessCode: accessCode?.trim(),
        referralCode: referralCode?.trim(),
      }).then((response) => {
        if (response.status === 200) {
          analytics.track('Account Created', { email })
        }
      })
    },
  }

  const ModeratorApi = {
    messageAll: (groupId: string, subject: string, message: string) =>
      CabanaApi.post(`/moderator/message/${groupId}`, { subject, message }),
    moderators: (sort?: string, limit?: number) =>
      CabanaApi.get(
        `/moderator/card?sort=${sort}${limit ? `&limit=${limit}` : ''}`
      ).then((response) => response.data),
    moderatorDetails: (moderatorId: string) =>
      CabanaApi.get(`/moderator/details/${moderatorId}`).then(
        (response) => response.data
      ),
  }

  const SearchApi = {
    popular: (): Promise<string[]> =>
      CabanaApi.get('/search/popular').then((response) => response.data),
    suggested: (basis: string): Promise<string[]> =>
      CabanaApi.get('/search/suggested', { params: { basis } }).then(
        (response) => response.data
      ),
  }

  const ActivityCardApi = {
    search: (
      query: string,
      timeFilters: string[],
      categoryFilters: string[],
      page: number
    ): Promise<Activity[]> => {
      return CabanaApi.get('/activity-card/search', {
        params: {
          query: query || undefined,
          time: timeFilters,
          category: categoryFilters,
          page,
          size: 10,
        },
      }).then((response) => response.data)
    },
    liveAndUpcoming: (): Promise<Activity[]> =>
      CabanaApi.get('/activity-card/live-and-upcoming-groups').then(
        (response) => response.data?.map((item) => useParseActivity(item))
      ),
    recentlyAdded: (): Promise<Activity[]> =>
      CabanaApi.get('/activity-card/recently-added-content').then((response) =>
        response.data?.map((item) => useParseActivity(item))
      ),
    calendar: (): Promise<Activity[]> =>
      CabanaApi.get('/activity-card/calendar-view').then((response) =>
        response.data?.map((item) => useParseActivity(item))
      ),
    getForYouGroups: () =>
      CabanaApi.get(`/activity-card/for-you`).then((response) =>
        response.data?.map((item) => useParseActivity(item))
      ),
    getMyGroups: () =>
      CabanaApi.get(`/activity-card/my-groups`).then((response) =>
        response.data?.map((item) => useParseActivity(item))
      ),
    dailyRecs: () =>
      CabanaApi.get(`/activity-card/daily-recommendations`).then((response) =>
        response.data?.map((item) => useParseActivity(item))
      ),
  }

  const ActivityDetailsApi = {
    details: (activityId: string): Promise<Activity> =>
      CabanaApi.get(`/activity-details/${activityId}`).then((response) => {
        //TODO Remove this, backend is doing anything when the activity ends, so we need to change any property to force a re-render
        const data = { ...response.data, responseDate: new Date() }
        return data
      }),
  }

  const ActivityApi = {
    register: (activity: Activity): Promise<any> =>
      CabanaApi.post('/activity-card/register', {
        activityId: activity.id,
      }).then((response) => {
        analytics.track('Group Registered', useGroupEventProperties(activity))
        return response
      }),
    unregister: (activity: Activity): Promise<any> =>
      CabanaApi.post('/activity-card/unregister', {
        activityId: activity.id,
      }).then((response) => {
        analytics.track('Group Unregistered', useGroupEventProperties(activity))
        return response
      }),
    complete: (activity: Activity): Promise<any> =>
      CabanaApi.post(`/activity/${activity.id}/complete`).then((response) => {
        return response
      }),
    getBookmarks: () =>
      CabanaApi.get(`/activity/bookmark`).then((response) => response.data),
    bookmark: (activityId: string): Promise<any> =>
      CabanaApi.put('/activity/bookmark', activityId).then(() =>
        analytics.track('Activity Bookmarked', {
          activityId,
        })
      ),
    unbookmark: (activityId: string): Promise<any> =>
      CabanaApi.delete('/activity/bookmark', {
        data: activityId,
      }),
    like: (activityId: string): Promise<any> =>
      CabanaApi.put('/activity/like', activityId).then(() =>
        analytics.track('Activity Liked', { activityId })
      ),
    unlike: (activityId: string): Promise<any> =>
      CabanaApi.delete('/activity/like', {
        data: activityId,
      }),
  }

  const ChatApi = {
    getMessages: (chatId: string) =>
      CabanaApi.get(`/chat/${chatId}/messages`).then(
        (response) => response.data.messages
      ),
    sendMessage: (chatId: string, message: string) =>
      CabanaApi.post(`/chat/${chatId}/message`, { message }),
  }

  const EapApi = {
    getEapPage: () => CabanaApi.get('/eap'),
  }

  const ProfessionalRoleApi = {
    getProfessionalRoles: () =>
      CabanaApi.get('/professional-role').then((response) => response.data),
  }

  const OrganizationApi = {
    getOrganizations: () =>
      CabanaApi.get('/v2/organizations').then((response) => response.data),
  }

  const RoomApi = {
    changeAlias: (roomId: string, alias: string) =>
      CabanaApi.post(`/room/${roomId}/alias`, { alias }),
    getRoster: (roomId: string) =>
      CabanaApi.get(`/roster/${roomId}`).then((response) => response.data),
    join: (roomId: string) => CabanaApi.post(`/room/${roomId}/join`),
    end: (roomId: string) => CabanaApi.post(`/room/${roomId}/end-group`),
    start: (roomId: string) => CabanaApi.post(`/room/${roomId}/start`),
    leave: (roomId: string) => CabanaApi.post(`/room/${roomId}/leave`),
    toggleMicrophone: (roomId: string) =>
      CabanaApi.post(`/room/${roomId}/toggle-microphone`),
    toggleCamera: (roomId: string) =>
      CabanaApi.post(`/room/${roomId}/toggle-camera`),
    toggleHandRaised: (roomId: string) =>
      CabanaApi.post(`/room/${roomId}/toggle-hand-raised`),
    toggleScreen: (roomId: string) =>
      CabanaApi.post(`/room/${roomId}/toggle-screenshare`),
    muteParticipant: (roomId: string, userId: string) => {
      CabanaApi.post(`/room/${roomId}/mute-participant`, { userId })
    },
    setActiveSpeaker: (roomId: string, value: boolean) => {
      const endpoint = value ? 'set-active-speaker' : 'clear-active-speaker'
      CabanaApi.post(`/room/${roomId}/${endpoint}`)
    },
    submitModeratorFeedback: (roomId: string, feedback) => {
      CabanaApi.post(`/room/${roomId}/moderator/submit-feedback`, feedback)
    },
    submitParticipantFeedback: (roomId: string, feedback) => {
      CabanaApi.post(`/room/${roomId}/participant/submit-feedback`, feedback)
    },
    startTyping: (roomId: string) =>
      CabanaApi.post(`/room/${roomId}/start-typing`),
    stopTyping: (roomId: string) =>
      CabanaApi.post(`/room/${roomId}/stop-typing`),
    setParticipantRole: (roomId: string, role: string) =>
      CabanaApi.put(`/room/${roomId}/role`, { role }),
  }

  const RoomApiV2 = {
    start: (roomId: string) => CabanaApi.post(`/v2/room/${roomId}/start`),
  }

  const PersonalizationApi = {
    selectGoal: (goal: String, isCustomGoal) =>
      CabanaApi.post(`/userprofile/personalization/goal`, {
        goal: isCustomGoal ? 'BOOST_MOOD' : goal.trim(),
        customGoal: isCustomGoal ? goal.trim() : null,
      }),
    selectMinutes: (minutes) =>
      CabanaApi.post(`/userprofile/personalization/minutes`, { minutes }),
    selectOasis: (oasis) =>
      CabanaApi.post(`/userprofile/personalization/oasis`, { oasis }),
    selectProfessionalRole: (professionalRoles) =>
      CabanaApi.post(`/userprofile/personalization/professional-role`, {
        professionalRoles,
      }),
  }

  const TestApi = {
    meetNow: (data) =>
      CabanaApi.post('/testing/meet-now', data).then(
        (response) => response.data
      ),
    unregister: (roomId, userId) =>
      CabanaApi.post('/testing/unregister', {
        roomId: { value: roomId },
        userId: { value: userId },
      }),
    register: async (roomId, userId, role) =>
      await CabanaApi.post('/testing/register', {
        roomId: { value: roomId },
        userId: { value: userId },
        role,
      }),
  }

  const VibeCheckApi = {
    completeVibeCheck: (selectedMood, emotionNote) => {
      return CabanaApi.post(`activity-card/vibe-check`, {
        selectedMood,
        emotionNote,
      })
    },
    history: (page) =>
      CabanaApi.get(`activity-card/vibe-check/history`, {
        params: { page: page - 1 },
      }).then((response) => response.data),
  }

  const ReportApi = {
    member: (
      organizationNameList: [],
      startDate: Date,
      endDate: Date
    ): Promise<string[]> =>
      CabanaApi.post('/report/members', {
        organizationNameList,
        startDate,
        endDate,
      }).then((response) => response.data),

    participation: (
      organizationNameList: [],
      startDate: Date,
      endDate: Date
    ): Promise<string[]> =>
      CabanaApi.post('/report/participation', {
        organizationNameList,
        startDate,
        endDate,
      }).then((response) => response.data),

    contentUtilization: (
      organizationNameList: [],
      startDate: Date,
      endDate: Date
    ): Promise<string[]> =>
      CabanaApi.post('/report/content-utilization', {
        organizationNameList,
        startDate,
        endDate,
      }).then((response) => response.data),

    mentalHealthMinutes: (
      organizationNameList: [],
      startDate: Date,
      endDate: Date
    ): Promise<string[]> =>
      CabanaApi.post('/report/mental-health-minutes', {
        organizationNameList,
        startDate,
        endDate,
      }).then((response) => response.data),

    vibeCheckData: (
      organizationNameList: [],
      startDate: Date,
      endDate: Date
    ): Promise<string[]> =>
      CabanaApi.post('/report/vibe-check-data', {
        organizationNameList,
        startDate,
        endDate,
      }).then((response) => response.data),

    emailLeads: (startDate: Date, endDate: Date): Promise<string[]> =>
      CabanaApi.post('/report/email-leads', {
        startDate,
        endDate,
      }).then((response) => response.data),
  }

  const ReferralApi = {
    rewards: (): Promise<string[]> =>
      CabanaApi.get('/rewards').then((response) => response.data),
    reclaimReward: (id: string): Promise<string[]> =>
      CabanaApi.post(`/rewards/${id}/claim`),
    process: (referralCode: string): Promise<any> =>
      CabanaApi.post('/member/referral', referralCode),
  }

  const FeatureApi = {
    features: (): Promise<Object> =>
      CabanaApi.get('/features').then((response) => response.data),
  }

  const VoyageApi = {
    screener: (responses: []) =>
      CabanaApi.post('/voyage/screener', {
        responses,
      }),
    balancedSelf: () =>
      CabanaApi.get('/voyage/balanced-self').then((response) => response.data),
    focusArea: (focusArea: string) =>
      CabanaApi.get(`/voyage/balanced-self/focus-area/${focusArea}`).then(
        (response) => response.data
      ),
    path: (focusArea: string, id: string) =>
      CabanaApi.get(
        `/voyage/balanced-self/focus-area/${focusArea}/path/${id}`
      ).then((response) => response.data),
    completeStep: (id: string) =>
      CabanaApi.post(`/voyage/step/${id}/complete`).then(
        (response) => response.data
      ),
    compass: () => CabanaApi.get('/compass').then((response) => response.data),
    completeCompass: (values) => CabanaApi.put('/compass', values),
  }

  const IngestApi = {
    ingest: (actions) => {
      invalidateQueries(['focusAreas', 'BalancedSelf', 'FocusArea', 'Path'])
      return CabanaApi.post('/ingest', actions).then(
        (response) => response.data
      )
    },
  }

  const AdminApi = {
    resetVoyage: () => CabanaApi.post('/admin/voyage/reset'),
  }

  const CollectionApi = {
    collection: (slug: string) =>
      CabanaApi.get(`/collections/${slug}`).then((response) => response.data),
    collections: () =>
      CabanaApi.get('/collections/cards').then((response) => response.data),
  }

  const LeaderboardApi = {
    allTimeLeaderboard: () =>
      CabanaApi.get('/leaderboard').then((response) => response.data),
    monthlyLeaderboard: () =>
      CabanaApi.get('/leaderboard/monthly').then((response) => response.data),
    memberLeaderboard: () =>
      CabanaApi.get('/leaderboard/member').then((response) => response.data),
  }

  return (
    <CabanaApiContext.Provider
      value={{
        AuthPasswordlessApi,
        ActivityApi,
        ActivityCardApi,
        ActivityDetailsApi,
        ChatApi,
        EapApi,
        MemberProfileApi,
        MemberSignupApi,
        OrganizationApi,
        PersonalizationApi,
        ProfessionalRoleApi,
        RoomApi,
        RoomApiV2,
        SearchApi,
        TestApi,
        VibeCheckApi,
        ModeratorApi,
        ReportApi,
        ReferralApi,
        FeatureApi,
        VoyageApi,
        IngestApi,
        AdminApi,
        CollectionApi,
        LeaderboardApi,
      }}>
      {Platform.OS === 'web' && (
        <Modal isVisible={webModalShown}>
          <Center width="100%" h="20%">
            <Box width="384px" background="white" borderRadius="10px">
              <Heading fontSize="24px" mx="24px" my="52px" textAlign="center">
                Please refresh this browser page to continue
              </Heading>
            </Box>
          </Center>
        </Modal>
      )}
      {children}
    </CabanaApiContext.Provider>
  )
}

const goToAppStore = () => {
  const appId = '1578759844'
  const url = `itms-apps://itunes.apple.com/app/id${appId}`

  Linking.canOpenURL(url).then((supported) => {
    if (supported) {
      Linking.openURL(url)
    } else {
      console.debug(`Don't know how to open URL: ${url}`)
    }
  })
}
