import { useBreakpointValue } from 'native-base'
import { SafeAreaView, StyleSheet } from 'react-native'
import BrowserOrientation from '../../components/liveGroup/orientation/BrowserOrientation'
import MobileOrientation from '../../components/liveGroup/orientation/MobileOrientation'
import secureScreen from '../../hooks/authentication/secureScreen'

const styles = StyleSheet.create({
  topSafeArea: {
    flex: 0,
    backgroundColor: '#F5F5F5',
  },
  bottomSafeArea: {
    flex: 1,
    backgroundColor: '#F5F5F5',
  },
})

const fakeRoom = {
  id: '12345',
  title: 'Sample Room',
  startTime: new Date('2023-10-01T10:00:00Z'),
  endTime: new Date('2023-10-01T12:00:00Z'),
  attendees: [],
}

const OrientationScreen = ({ route }) => {
  const { roomId } = route.params
  // const { room } = useRoom(roomId)

  const breakpoint = useBreakpointValue({
    base: 'mobile',
    lg: 'browser',
  })
  return (
    <>
      <SafeAreaView style={styles.topSafeArea} />
      <SafeAreaView style={styles.bottomSafeArea}>
        {breakpoint === 'mobile' ? (
          <MobileOrientation />
        ) : (
          <BrowserOrientation room={fakeRoom} />
        )}
      </SafeAreaView>
    </>
  )
}

export default secureScreen(OrientationScreen)
