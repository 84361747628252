import { Button, HStack, Input, Text, View } from 'native-base'
import { useState } from 'react'
import Orb from '../../room/Orb'

const AliasEntry = ({ setStepCondition, stepIndex }) => {
  const [inputValue, setInputValue] = useState('')

  const handleInputChange = (text) => {
    setInputValue(text)
  }

  const handleSave = () => {
    if (inputValue) {
      setStepCondition(stepIndex, true)
    }
  }

  return (
    <View>
      <Text>This is what you'll be called in the group</Text>
      <HStack mt="1" mb="5">
        <Input
          size="lg"
          flex={1}
          value={inputValue}
          onChangeText={handleInputChange}
        />
        <Button
          colorScheme="primary"
          ml="3"
          onPress={handleSave}
          isDisabled={!inputValue}>
          Save
        </Button>
      </HStack>
      <Text>This is what you'll look like in the group</Text>
      <HStack mt="16px" alignItems="center">
        <Orb
          attendee={{
            alias: inputValue,
            seatNumber: 1,
          }}
        />
        <Text color="primary.900" ml="10px" fontSize="16px" fontWeight="500">
          {inputValue}
        </Text>
      </HStack>
    </View>
  )
}

export default AliasEntry
