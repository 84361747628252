import { Box, KeyboardAvoidingView, Text } from 'native-base'
import { useState } from 'react'
import { Platform, SafeAreaView } from 'react-native'
import { CHAT, INFO, PEOPLE } from '../../../constants/roomConstants'
import Tabs from '../../_shared/layout/Tabs'

const TabContent = ({ children }) => (
  <Box py={6} px={4} justifyContent="center" alignItems="center">
    {children}
  </Box>
)

const tabsData = {
  [PEOPLE]: {
    title: 'People',
    content: (
      <TabContent>
        <Text>People</Text>
      </TabContent>
    ),
  },
  [INFO]: {
    title: 'Info',
    content: (
      <TabContent>
        <Text>Info</Text>
      </TabContent>
    ),
  },
  [CHAT]: {
    title: 'Chat',
    content: (
      <TabContent>
        <Text>Chat</Text>
      </TabContent>
    ),
  },
}

const MobileRoom = () => {
  const [tabSelected, setTabSelected] = useState(PEOPLE)

  return (
    <KeyboardAvoidingView
      background="white"
      behavior={Platform.OS === 'ios' ? 'padding' : null}
      style={{ flex: 1 }}>
      <SafeAreaView>
        <Box
          background="white"
          height="40px"
          justifyContent="center"
          alignItems="center">
          <Text>Info bar</Text>
        </Box>
        <Box background="black" height="252px" position="relative">
          <Box
            position="absolute"
            width="192px"
            height="56px"
            alignSelf="center"
            bottom={4}
            marginLeft="auto"
            marginRight="auto"
            background="white"
            justifyContent="center"
            alignItems="center">
            <Text>Button bar</Text>
          </Box>
        </Box>
        <Tabs
          h="container"
          tabSelected={tabSelected}
          selectTab={setTabSelected}
          tabsData={tabsData}
        />
      </SafeAreaView>
    </KeyboardAvoidingView>
  )
}

export default MobileRoom
