import {
  Button,
  Center,
  Heading,
  HStack,
  Select,
  Text,
  VStack,
} from 'native-base'
import { useRef, useState } from 'react'
import secureScreen from '../hooks/authentication/secureScreen'
import useCameras from '../hooks/useCameras'
import useMeeting, { Meeting } from '../hooks/useMeeting'
import useMicrophones from '../hooks/useMicrophones'
import useRoom from '../hooks/useRoom'
import { useCabanaApi } from '../providers/CabanaApiProvider'
import { useUserProfileContext } from '../providers/UserProfileProvider'

const RoomTestScreen = ({ route }) => {
  const roomId = route.params?.roomId
  const { room, error } = useRoom(roomId)
  const { microphones } = useMicrophones()
  const { cameras } = useCameras()
  const { join } = useMeeting()
  const { userProfile } = useUserProfileContext()
  const { RoomApiV2 } = useCabanaApi()

  const [selectedMicrophone, setSelectedMicrophone] = useState<string>(null)
  const [selectedCamera, setSelectedCamera] = useState<string>(null)
  const [meeting, setMeeting] = useState<Meeting | null>(null)
  const videoRef = useRef<HTMLVideoElement>(null)

  const scrub = (room) => {
    const scrubbed = JSON.parse(JSON.stringify(room))
    scrubbed?.attendees?.forEach((attendee) => {
      if (!attendee.token) return
      attendee.token = attendee.token.slice(0, 20) + '...'
    })
    return scrubbed
  }

  const me = () =>
    room.attendees.find((attendee) => attendee.id === userProfile.id)

  const start = () => RoomApiV2.start(roomId)

  const joinMeeting = (token: string) => {
    return join(token).then((meeting) => {
      meeting.onNewAudio((audio) => {
        audio.play()
        return () => audio.stop()
      })
      meeting.onNewVideo((video) => {
        video.play(videoRef.current)
      })
      setMeeting(meeting)
    })
  }

  const setMicrophone = () => {
    const microphone = microphones.find(
      (microphone) => microphone.name === selectedMicrophone
    )
    meeting.setMicrophone(microphone)
  }

  const setCamera = () => {
    const camera = cameras.find((camera) => camera.name === selectedCamera)
    meeting.setCamera(camera)
  }

  return (
    <Center>
      <VStack style={{ gap: 10 }}>
        <Heading>Room Test - {roomId}</Heading>
        <pre>{JSON.stringify(scrub(room), null, 2)}</pre>
        <Text color={'error'}>{error}</Text>
        <Button onPress={() => start()}>Start</Button>
        <Button onPress={() => joinMeeting(me().token)}>Join</Button>
        <HStack>
          <Select
            placeholder="Select Microphone"
            selectedValue={selectedMicrophone}
            onValueChange={setSelectedMicrophone}>
            {microphones.map((microphone) => (
              <Select.Item
                key={microphone.name}
                label={microphone.name}
                value={microphone.name}
              />
            ))}
          </Select>
          <Button onPress={() => setMicrophone()}>Set Microphone</Button>
        </HStack>
        <HStack>
          <Select
            placeholder="Select Camera"
            selectedValue={selectedCamera}
            onValueChange={setSelectedCamera}>
            {cameras.map((camera) => (
              <Select.Item
                key={camera.name}
                label={camera.name}
                value={camera.name}
              />
            ))}
          </Select>
          <Button onPress={() => setCamera()}>Set Camera</Button>
        </HStack>
        <video ref={videoRef} playsInline autoPlay>
          Video goes here
        </video>
      </VStack>
    </Center>
  )
}

export default secureScreen(RoomTestScreen)
