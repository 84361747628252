import { Alert, Button, Flex, Text } from 'native-base'
import { useEffect, useState } from 'react'
import FaIcon from '../../_shared/FaIcon'
import FormLabel from '../../_shared/form/FormLabel'
import TextLink from '../../_shared/text/TextLink'

const PermissionCheck = ({
  hasPermission,
  requestPermission,
  hasCamera = false,
}) => {
  const permissionText = hasCamera ? 'Permissions' : 'Mic Permission'
  const buttonTypes = [
    {
      color: '',
      iconName: null,
      text: 'Loading...',
      isDisabled: true,
    },
    {
      permissionValue: true,
      color: 'secondary',
      iconName: 'check',
      text: `${permissionText} Allowed`,
    },
    {
      permissionValue: false,
      color: 'error',
      iconName: 'close',
      text: `${permissionText} Denied`,
    },
    {
      permissionValue: null,
      color: '',
      iconName: null,
      text: `Request ${permissionText}`,
    },
  ]

  const [buttonType, setButtonType] = useState(buttonTypes[0])

  useEffect(() => {
    const newButtonType = buttonTypes.find(
      (button) => button.permissionValue === hasPermission
    )
    setButtonType(newButtonType)
  }, [hasPermission])

  return (
    <Flex mb="16px">
      <FormLabel color="muted.500">Permissions</FormLabel>
      <Button
        variant="outline"
        borderColor="gray.300"
        colorScheme={buttonType.color}
        isDisabled={buttonType.isDisabled}
        onPress={requestPermission}
        leftIcon={
          buttonType.iconName && <FaIcon name={buttonType.iconName} size={16} />
        }>
        {buttonType.text}
      </Button>
      {hasPermission === false && (
        <Alert colorScheme="error" variant="subtle" mt="3">
          {hasCamera ? (
            <Text>
              For the best Cabana experience, we need permissions.{' '}
              <TextLink href="https://www.mycabana.health/mic-support">
                Click here
              </TextLink>{' '}
              to learn how to grant permissions on your device.
            </Text>
          ) : (
            <Text>
              We’re having trouble enabling your microphone to work in the live
              group.{' '}
              <TextLink href="https://www.mycabana.health/mic-support">
                Click here
              </TextLink>{' '}
              to learn how to grant permissions, alternatively you can join as
              “Chat only” with no mic.
            </Text>
          )}
        </Alert>
      )}
    </Flex>
  )
}

export default PermissionCheck
