import {
  faAlarmClock,
  faArrowRightFromBracket,
  faArrowUpFromSquare,
  faArrowUpRightFromSquare,
  faBinoculars,
  faBolt,
  faBook,
  faBookmark,
  faBookOpen,
  faBooks,
  faBrainCircuit,
  faBullhorn,
  faBullseyeArrow,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleInfo,
  faCirclePlay,
  faCircleSmall,
  faCloudArrowDown,
  faComment,
  faCommentLines,
  faComments,
  faCompass,
  faDisplay,
  faDisplaySlash,
  faEdit,
  faEllipsis,
  faEllipsisVertical,
  faEye,
  faEyeSlash,
  faFaceAngry,
  faFaceLaugh,
  faFaceMeh,
  faFacePensive,
  faFaceRelieved,
  faFaceSmileHearts,
  faFaceSunglasses,
  faFaceWorried,
  faFileShield,
  faFileSignature,
  faGear,
  faHandHoldingHeart,
  faHeadphones,
  faHeartPulse,
  faHouse,
  faIdBadge,
  faLeaf,
  faLifeRing,
  faLighthouse,
  faList,
  faMagnifyingGlass,
  faMedal,
  faPalette,
  faPaperPlaneTop,
  faPen,
  faPencil,
  faPlay,
  faPlus,
  faQuestionCircle,
  faRotateLeft,
  faRotateRight,
  faScrewdriverWrench,
  faSeedling,
  faSignal,
  faSignalFair,
  faSignalGood,
  faSignalSlash,
  faSignalStrong,
  faSignalWeak,
  faSparkles,
  faSpinnerThird,
  faStar,
  faThumbsDown,
  faThumbsUp,
  faTrashCan,
  faTreePalm,
  faTrophy,
  faUmbrella,
  faUmbrellaBeach,
  faUpRightAndDownLeftFromCenter,
  faUser,
  faUserGroup,
  faVideo,
  faVideoSlash,
  faWavePulse,
  faWrench,
  faXmark,
} from '@fortawesome/pro-regular-svg-icons'
import {
  faAlarmClock as faAlarmClockSolid,
  faBookmark as faBookmarkSolid,
  faBrainCircuit as faBrainCircuitSolid,
  faCheckCircle,
  faCommentLines as faCommentLinesSolid,
  faCompass as faCompassSolid,
  faEnvelope as faEnvelopeSolid,
  faGear as faGearSolid,
  faHand,
  faHandHoldingHeart as faHandHoldingHeartSolid,
  faInfoCircle as faInfoCircleSolid,
  faList as faListSolid,
  faLockKeyhole as faLockKeyholeSolid,
  faMedal as faMedalSolid,
  faMicrophone,
  faMicrophoneSlash,
  faPause,
  faPlay as faPlaySolid,
  faSeedling as faSeedlingSolid,
  faStar as faStarSolid,
  faThumbsDown as faThumbsDownSolid,
  faThumbsUp as faThumbsUpSolid,
  faUmbrella as faUmbrellaSolid,
  faUserPlus as faUserPlusSolid,
} from '@fortawesome/pro-solid-svg-icons'

export default [
  faArrowUpRightFromSquare,
  faCheckCircle,
  faPlaySolid,
  faAlarmClock,
  faStarSolid,
  faStar,
  faUmbrellaSolid,
  faUmbrellaBeach,
  faHeadphones,
  faPen,
  faPencil,
  faPlus,
  faTreePalm,
  faBook,
  faBooks,
  faBookmark,
  faBookmarkSolid,
  faBookOpen,
  faBullseyeArrow,
  faScrewdriverWrench,
  faBolt,
  faCloudArrowDown,
  faArrowUpFromSquare,
  faArrowRightFromBracket,
  faUpRightAndDownLeftFromCenter,
  faLifeRing,
  faGear,
  faFileSignature,
  faFileShield,
  faBullhorn,
  faCircle,
  faCircleInfo,
  faEdit,
  faIdBadge,
  faQuestionCircle,
  faPlay,
  faPause,
  faRotateRight,
  faRotateLeft,
  faXmark,
  faStar,
  faUser,
  faHouse,
  faBinoculars,
  faCheck,
  faMicrophone,
  faMicrophoneSlash,
  faVideo,
  faVideoSlash,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faMagnifyingGlass,
  faArrowUpFromSquare,
  faEllipsis,
  faEllipsisVertical,
  faCircleSmall,
  faComments,
  faComment,
  faHand,
  faThumbsUp,
  faThumbsDown,
  faThumbsUpSolid,
  faThumbsDownSolid,
  faSignalSlash,
  faSignalWeak,
  faSignalFair,
  faSignalGood,
  faSignalStrong,
  faSignal,
  faEye,
  faEyeSlash,
  faUserGroup,
  faWavePulse,
  faPaperPlaneTop,
  faDisplay,
  faDisplaySlash,
  faArrowRightFromBracket,
  faUpRightAndDownLeftFromCenter,
  faCirclePlay,
  faPalette,
  faSparkles,
  faTrashCan,
  faTrophy,
  faFaceLaugh,
  faFaceRelieved,
  faFaceSmileHearts,
  faFaceAngry,
  faFacePensive,
  faFaceMeh,
  faFaceSunglasses,
  faFaceWorried,
  faWrench,
  faLeaf,
  faHeartPulse,
  faInfoCircleSolid,
  faAlarmClockSolid,
  faLighthouse,
  faCompassSolid,
  faHandHoldingHeartSolid,
  faGearSolid,
  faCommentLinesSolid,
  faBrainCircuitSolid,
  faListSolid,
  faSeedlingSolid,
  faSpinnerThird,
  faHandHoldingHeart,
  faCompass,
  faCommentLines,
  faBrainCircuit,
  faList,
  faSeedling,
  faUmbrella,
  faEnvelopeSolid,
  faLockKeyholeSolid,
  faUserPlusSolid,
  faMedal,
  faMedalSolid,
]
