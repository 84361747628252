import { useRef } from 'react'
import {
  connect,
  LocalAudioTrack,
  LocalVideoTrack,
  RemoteAudioTrack,
  RemoteTrack,
  RemoteVideoTrack,
  Room,
} from 'twilio-video'
import { Camera } from './useCameras'
import { Audio, Meeting, UseMeeting, Video } from './useMeeting'
import { Microphone } from './useMicrophones'

const useMeeting: () => UseMeeting = () => {
  const room = useRef<Room>(null)
  const microphoneTrack = useRef<LocalAudioTrack | null>(null)
  const cameraTrack = useRef<LocalVideoTrack | null>(null)

  const join = async (token: string) => {
    room.current = await connect(token, { audio: false, video: false })

    const meeting: Meeting = {
      setMicrophone: async (microphone: Microphone) => {
        if (microphoneTrack.current) {
          room.current.localParticipant.unpublishTrack(microphoneTrack.current)
          microphoneTrack.current.stop()
          microphoneTrack.current = null
        }

        const stream = await navigator.mediaDevices.getUserMedia({
          audio: { deviceId: microphone.object.deviceId },
        })
        const track = stream.getAudioTracks()[0]
        const audioTrack = new LocalAudioTrack(track)
        await room.current.localParticipant.publishTrack(audioTrack)
        microphoneTrack.current = audioTrack
      },
      mute: () => {
        room.current.localParticipant.audioTracks.forEach((publication) =>
          publication.track.disable()
        )
      },
      unmute: () => {
        room.current.localParticipant.audioTracks.forEach((publication) =>
          publication.track.enable()
        )
      },
      setCamera: async (camera: Camera) => {
        if (cameraTrack.current) {
          room.current.localParticipant.unpublishTrack(cameraTrack.current)
          cameraTrack.current.stop()
          cameraTrack.current = null
        }

        const stream = await navigator.mediaDevices.getUserMedia({
          video: { deviceId: camera.object.deviceId },
        })
        const track = stream.getVideoTracks()[0]
        const videoTrack = new LocalVideoTrack(track)
        await room.current.localParticipant.publishTrack(videoTrack)
        cameraTrack.current = videoTrack
      },
      cameraOff: () => {
        room.current.localParticipant.videoTracks.forEach((publication) =>
          publication.track.disable()
        )
      },
      cameraOn: () => {
        room.current.localParticipant.videoTracks.forEach((publication) =>
          publication.track.enable()
        )
      },
      onNewAudio: (callback) => {
        room.current.on('trackSubscribed', (track: RemoteTrack) => {
          if (track.kind === 'audio') {
            callback(createAudio(track))
          }
        })
      },
      onNewVideo: (callback) => {
        room.current.on('trackSubscribed', (track: RemoteTrack) => {
          if (track.kind === 'video') {
            callback(createVideo(track))
          }
        })
      },
    }
    return meeting
  }

  const createAudio: (track: RemoteAudioTrack) => Audio = (track) => ({
    play: () => track.attach(),
    stop: () => track.detach().forEach((el: HTMLMediaElement) => el.remove()),
  })

  const createVideo: (track: RemoteVideoTrack) => Video = (track) => ({
    play: (element: HTMLVideoElement) => track.attach(element),
    stop: () => track.detach().forEach((el: HTMLMediaElement) => el.remove()),
  })

  return { join }
}

export default useMeeting
