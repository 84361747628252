import { useEffect } from 'react'
import useWebSocket from './useWebSocket'

const useSubscription = (topic: string, callback: (message: any) => void) => {
  const { subscribe, status } = useWebSocket()

  useEffect(() => {
    if (status !== 'connected') return () => {}

    const unsubscribe = subscribe(topic, callback)
    return () => unsubscribe()
  }, [topic, callback, status])

  return { status }
}

export default useSubscription
