import { HStack } from 'native-base'
import { useState } from 'react'
import AliasEntry from './AliasEntry'
import DeviceSettings from './DeviceSettings'
import JoinRoom from './JoinRoom'
import OrientationStep from './OrientationStep'

const OrientationStepper = () => {
  const [stepperIndex, setStepperIndex] = useState(0)
  const [stepConditions, setStepConditions] = useState([false, false, false])
  const [userHasMic, setUserHasMic] = useState(false)

  const setStepCondition = (index, condition) => {
    const newConditions = [...stepConditions]
    newConditions[index] = condition
    setStepConditions(newConditions)

    if (index === 1) {
      setUserHasMic(condition)
      if (condition) {
        newConditions[2] = true
        setStepConditions(newConditions)
      }
    }
  }

  const steps = [
    {
      id: 'alias-entry',
      component: (
        <AliasEntry setStepCondition={setStepCondition} stepIndex={0} />
      ),
    },
    {
      id: 'device-settings',
      component: (
        <DeviceSettings setStepCondition={setStepCondition} stepIndex={1} />
      ),
    },
    {
      id: 'join-room',
      component: (
        <JoinRoom
          condition={stepConditions[2]}
          handlePress={() => console.log('Join Room pressed')}
          hasMicrophone={userHasMic}
        />
      ),
    },
  ]
  const titles = ['Enter your nickname', 'Audio Settings', "You're ready to go"]

  const handleGoToNextStep = (index) => {
    if (stepConditions[index]) {
      setStepperIndex(index + 1)
    }
  }

  const handleGoToPrevStep = (index) => {
    setStepperIndex(index - 1)
  }

  return (
    <HStack space={4} justifyContent="center">
      {steps.map((step, index) => (
        <OrientationStep
          key={step.id}
          stepperIndex={stepperIndex}
          index={index}
          setStepperIndex={setStepperIndex}
          title={titles[index]}
          body={step.component}
          condition={stepConditions[index]}
          isDisabled={
            index > stepperIndex ||
            !stepConditions.slice(0, index).every(Boolean)
          }
          isLast={index === steps.length - 1}
          onPressBack={() => handleGoToPrevStep(index)}
          onPressNext={() => handleGoToNextStep(index)}
        />
      ))}
    </HStack>
  )
}

export default OrientationStepper
