import { Client, IMessage } from '@stomp/stompjs'
import { useEffect, useRef, useState } from 'react'
import Configuration from '../../../domain/services/ConfigurationService'
import useAuthentication from './authentication/useAuthentication'

interface WebSocket {
  subscribe: (topic: string, callback: (message: any) => void) => () => void
  status: string
}

const useWebSocket = (): WebSocket => {
  const [status, setStatus] = useState('disconnected')
  const { getAccessToken } = useAuthentication()
  const clientRef = useRef<Client | null>(null)
  const url = Configuration.CABANA_SERVICE_WEBSOCKET_URL

  useEffect(() => {
    if (!url) return

    const createClient = async () => {
      const accessToken = await getAccessToken()
      const client = new Client({
        brokerURL: `${url}?access_token=${accessToken}`,
        reconnectDelay: 1500,
        onConnect: () => setStatus('connected'),
        onDisconnect: () => setStatus('disconnected'),
        onStompError: (error) => {
          console.error('STOMP error', error)
          setStatus('error')
        },
        onWebSocketError: (error) => {
          console.log('WebSocket error', error)
          setStatus('error')
        },
      })
      client.activate()
      clientRef.current = client
    }
    createClient()

    return () => {
      clientRef.current?.deactivate()
    }
  }, [url])

  const subscribe = (topic: string, callback: (message: any) => void) => {
    if (!clientRef.current?.connected) {
      console.error('WebSocket is not connected')
      return () => {}
    }

    const subscription = clientRef.current.subscribe(
      topic,
      (message: IMessage) => {
        const body = JSON.parse(message.body)
        callback(body)
      }
    )

    return () => {
      subscription.unsubscribe()
    }
  }

  return { subscribe, status }
}

export default useWebSocket
