import { Button, Flex, HStack } from 'native-base'
import BaseLayout from '../../../layouts/BaseLayout'
import Card from '../../_shared/card/Card'
import FixedContainer from '../../_shared/layout/FixedContainer'
import LiveGroupHeading from '../LiveGroupHeading'
import VideoPreview from '../VideoPreview'
import DeviceSettings from './DeviceSettings'
import OrientationStepper from './OrientationStepper'

const isModerator = false

const BrowserOrientation = ({ room }) => {
  return (
    <BaseLayout>
      <FixedContainer size="lg">
        <LiveGroupHeading room={room} />
        {isModerator ? (
          <>
            <HStack space={3}>
              <Flex flex={2} width="650px">
                <Card
                  boxProps={{
                    w: '650px',
                  }}
                  title="Preview"
                  hasPadding={{ title: true, actions: true, body: false }}
                  body={<VideoPreview />}
                />
              </Flex>
              <Flex flex={1}>
                <Card
                  boxProps={{
                    h: '100%',
                  }}
                  title="Audio settings"
                  body={
                    <DeviceSettings stepIndex={0} setStepCondition={() => {}} />
                  }
                />
              </Flex>
            </HStack>
            <HStack alignSelf="flex-end" mt="3">
              <Button
                variant="outline"
                borderColor="muted.300"
                tintColor="#222B60"
                textDecorationColor="black"
                onPress={() => {}}>
                Cancel
              </Button>
              <Button
                isDisabled
                colorScheme="primary"
                ml="3"
                onPress={() => {}}>
                Enter room
              </Button>
            </HStack>
          </>
        ) : (
          <OrientationStepper />
        )}
      </FixedContainer>
    </BaseLayout>
  )
}

export default BrowserOrientation
